import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.object.to-string.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import DatePicker from "@/view/pages/partials/Datepicker.vue";
import { POST, PUT, CLEAR_ERROR } from "@/core/services/store/request.module";
import ValidationMixin from "@/core/plugins/validation-mixin";
export default {
  name: "create-or-update-warranty",
  mixins: [ValidationMixin],
  props: {
    dialogStatus: {
      type: Boolean,
      default: false
    },
    allCustomerList: {
      type: Array,
      default: function _default() {
        return new Array();
      }
    },
    allProductList: {
      type: Array,
      default: function _default() {
        return new Array();
      }
    },
    warrantyDetail: {
      type: Object,
      default: function _default() {
        return new Object();
      }
    }
  },
  components: {
    DatePicker: DatePicker
  },
  watch: {
    warrantyDetail: {
      deep: true,
      immediate: true,
      handler: function handler() {
        if (this.lodash.isEmpty(this.warrantyDetail) === false) {
          this.warrantyOption.id = this.warrantyDetail.id;
          this.warrantyOption.product = this.warrantyDetail.product_id;
          this.warrantyOption.customer = this.warrantyDetail.customer_id;
          this.warrantyOption.unique_id = this.warrantyDetail.warranty_unique_id;
          this.warrantyOption.description = this.warrantyDetail.warranty_description;
          this.warrantyOption.start_date = this.warrantyDetail.warranty_start_date;

          if (this.lodash.isEmpty(this.warrantyDetail.warranty_data) === false) {
            this.defaultItemWarranty = this.warrantyDetail.warranty_data.map(function (row) {
              return {
                warranty: row.warranty,
                value: row.value,
                field: row.field,
                id: row.id
              };
            });
          }
        } else {
          if (this.defaultItemWarranty.length <= 0) {
            this.pushWarranty();
          }
        }
      }
    },
    dialogStatus: function dialogStatus(param) {
      if (!param) {
        this.warrantyOption = new Object({
          id: null,
          customer: null,
          product: null,
          start_date: null,
          unique_id: null,
          description: null,
          warranty_data: new Array()
        });
        this.defaultItemWarranty = new Array();
      } else {
        if (this.defaultItemWarranty.length <= 0) {
          this.pushWarranty();
        }
      }
    }
  },
  data: function data() {
    return {
      formValid: true,
      pageLoading: false,
      startDatePicker: null,
      defaultItemWarranty: new Array(),
      warrantyOption: new Object({
        id: null,
        customer: null,
        product: null,
        start_date: null,
        unique_id: null,
        description: null,
        warranty_data: new Array()
      }),
      warrantyList: [{
        text: "30 Days",
        value: "30",
        type: "day"
      }, {
        text: "6 Months",
        value: "182",
        type: "day"
      }, {
        text: "1 Year",
        value: "365",
        type: "day"
      }, {
        text: "2 Years",
        value: "730",
        type: "day"
      }, {
        text: "3 Years",
        value: "1095",
        type: "day"
      }],
      warrantyDurationTypes: [{
        text: "Day(s)",
        value: "day"
      }, {
        text: "Week(s)",
        value: "week"
      }, {
        text: "Month(s)",
        value: "month"
      }, {
        text: "Year(s)",
        value: "year"
      }]
    };
  },
  methods: {
    pushWarranty: function pushWarranty() {
      if (this.defaultItemWarranty.length < this.warrantyDurationTypes.length) {
        this.defaultItemWarranty.push({
          warranty: 1,
          value: null,
          field: null,
          id: null
        });
      }
    },
    removeWarranty: function removeWarranty(index) {
      this.defaultItemWarranty.splice(index, 1);
    },
    updateWarranty: function updateWarranty() {
      var _this = this;

      if (!_this.$refs.warrantyForm.validate()) {
        return false;
      }

      _this.$store.dispatch(CLEAR_ERROR, {});

      _this.pageLoading = true;
      var formData = new Object({
        id: _this.lodash.toSafeInteger(_this.warrantyOption.id) || null,
        customer: _this.lodash.toSafeInteger(_this.warrantyOption.customer) || null,
        product: _this.lodash.toSafeInteger(_this.warrantyOption.product) || null,
        start_date: _this.warrantyOption.start_date,
        unique_id: _this.warrantyOption.unique_id,
        description: _this.warrantyOption.description,
        warranty_data: _this.defaultItemWarranty,
        duration: _this.defaultItemWarranty
      });
      var requestType = POST;

      if (_this.lodash.toSafeInteger(formData.id) > 0) {
        requestType = PUT;
      }

      _this.$store.dispatch(requestType, {
        url: "warranty",
        data: formData
      }).then(function () {
        _this.$emit("update:warranty", true);

        _this.$emit("close:dialog");
      }).catch(function (error) {
        _this.logError(error);
      }).finally(function () {
        _this.pageLoading = false;
      });
    }
  },
  computed: {
    warrantDefaultStartDate: function warrantDefaultStartDate() {
      return this.lodash.isEmpty(this.warrantyDetail) === false ? this.warrantyDetail.warranty_start_date : new Date().toISOString().substr(0, 10);
    },
    dialogWidth: function dialogWidth() {
      return this.lodash.toSafeInteger(document.body.clientWidth / 100 * 60);
    }
  }
};