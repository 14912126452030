import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.search.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.string.replace.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import moment from "moment-timezone";
import { QUERY, DELETE, GET } from "@/core/services/store/request.module";
import Barcode from "@/view/pages/partials/Barcode.vue";
import CommonMixin from "@/core/plugins/common-mixin";
import ListingTable from "@/view/pages/partials/Listing-Table.vue";
import ListingFooter from "@/view/pages/partials/Listing-Footer.vue";
import Dialog from "@/view/pages/partials/Dialog.vue";
import WarrantyDetail from "@/view/pages/warranty/Warranty-Detail.vue";
import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib";
import ListingTemplate from "@/view/pages/partials/Listing-Template.vue";
import ManageWarranty from "@/view/pages/partials/Create-Or-Update-Warranty.vue";
import { PaginationEventBus } from "@/core/lib/pagination.lib";
import PageHeaderCount from "@/view/pages/partials/PageHeaderCount.vue";
moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);
export default {
  mixins: [CommonMixin],
  name: "warranty-product-list",
  data: function data() {
    return {
      qrDialog: false,
      serial_number: null,
      qr_code_url: null,
      dates: new Array(),
      allCustomerList: new Array(),
      allProductList: new Array(),
      filteredProducts: new Array(),
      statusList: new Array(),
      warranty: new Array(),
      queryParams: new Object(),
      warrantyLoading: false,
      dataLoading: true,
      detailDialog: false,
      productType: null,
      searchProduct: null,
      productBarcode: null,
      productName: null,
      status: "all",
      totalPages: 0,
      paginationPageSize: 10,
      currentPage: 1,
      totalRows: 0,
      rowsOffset: 0,
      updateWarrantyDialog: false,
      addWarrantyDialog: false,
      voidWarrantyDialog: false,
      warrantyUpdate: new Object(),
      warrantyDetail: new Object(),
      momentObject: moment,
      customerList: new Array(),
      jobList: new Array(),
      productList: new Array(),
      filter: {
        search: null,
        customer: null,
        product: null,
        job: null
      },
      timeout: null,
      timeoutLimit: 500
    };
  },
  components: {
    Barcode: Barcode,
    Dialog: Dialog,
    ListingTable: ListingTable,
    ListingFooter: ListingFooter,
    ManageWarranty: ManageWarranty,
    WarrantyDetail: WarrantyDetail,
    PageHeaderCount: PageHeaderCount,
    ListingTemplate: ListingTemplate
  },
  methods: {
    getDetailText: function getDetailText(row) {
      if (row.ticket_id && row.ticket_barcode) {
        return "Job";
      }

      if (row.quotation_id && row.quotation_barcode) {
        return "Quotation";
      }

      if (row.invoice_id && row.invoice_barcode) {
        return "Invoice";
      }

      if (row.proposal_id && row.proposal_barcode) {
        return "Proposal";
      }
    },
    getDetailRoute: function getDetailRoute(row) {
      if (row.ticket_id && row.ticket_barcode) {
        return "job.detail";
      }

      if (row.quotation_id && row.quotation_barcode) {
        return "quotation.detail";
      }

      if (row.invoice_id && row.invoice_barcode) {
        return "invoice.detail";
      }

      if (row.proposal_id && row.proposal_barcode) {
        return "proposal.detail";
      }
    },
    getDetailBarcode: function getDetailBarcode(row) {
      if (row.ticket_id && row.ticket_barcode) {
        return row.ticket_barcode;
      }

      if (row.quotation_id && row.quotation_barcode) {
        return row.quotation_barcode;
      }

      if (row.invoice_id && row.invoice_barcode) {
        return row.invoice_barcode;
      }

      if (row.proposal_id && row.proposal_barcode) {
        return row.proposal_barcode;
      }
    },
    getDetailId: function getDetailId(row) {
      if (row.ticket_id && row.ticket_barcode) {
        return row.ticket_id;
      }

      if (row.quotation_id && row.quotation_barcode) {
        return row.quotation_id;
      }

      if (row.invoice_id && row.invoice_barcode) {
        return row.invoice_id;
      }

      if (row.proposal_id && row.proposal_barcode) {
        return row.proposal_id;
      }
    },
    qrDisplay: function qrDisplay(_ref) {
      var warranty_unique_id = _ref.warranty_unique_id,
          warranty_qr_code = _ref.warranty_qr_code;
      this.qr_code_url = warranty_qr_code;
      this.serial_number = warranty_unique_id;
      this.qrDialog = true;
    },
    updateSearch: function updateSearch(param) {
      var searchResult = this.lodash.filter(this.allProductList, function (row) {
        var result = false;

        if (!result && row.barcode && param) {
          result = row.barcode.toLowerCase().search(param.toLowerCase()) >= 0;
        }

        if (!result && row.name && param) {
          result = row.name.toLowerCase().search(param.toLowerCase()) >= 0;
        }

        if (!result && row.part_number && param) {
          result = row.part_number.toLowerCase().search(param.toLowerCase()) >= 0;
        }

        if (!result && row.serial_number && param) {
          result = row.serial_number.toLowerCase().search(param.toLowerCase()) >= 0;
        }

        return result;
      });
      this.filteredProducts = this.lodash.isArray(searchResult) ? searchResult : [];
    },
    routeToDetail: function routeToDetail(row) {
      if (row) {
        if (row.product_type === "goods") {
          this.$router.push(this.getDefaultRoute("product.detail", {
            params: {
              id: row.product_id
            }
          }));
        } else if (row.product_type === "equipment") {
          this.$router.push(this.getDefaultRoute("equipment.detail", {
            params: {
              id: row.product_id
            }
          }));
        }
      }
    },
    getProductType: function getProductType(type) {
      var result = null;

      switch (type) {
        case "goods":
          result = "Product";
          break;

        case "service":
          result = "Service";
          break;

        case "equipment":
          result = "Equipment";
          break;
      }

      return result;
    },
    clearFilter: function clearFilter(field) {
      var _this = this;

      switch (field) {
        case "search":
          _this.filter.search = null;
          break;

        case "dates":
          _this.dates = new Array();
          break;
      }

      _this.getWarranty();
    },
    updateWarranty: function updateWarranty() {
      var _this = this;

      _this.warrantyLoading = true;

      _this.$store.dispatch(DELETE, {
        url: "warranty/" + _this.warrantyDetail.id
      }).then(function () {
        _this.voidWarrantyDialog = false;
        _this.detailDialog = false;
        _this.warrantyDetail = new Object();
        _this.productType = null;
        _this.productBarcode = null;
        _this.productName = null;
      }).catch(function (error) {
        _this.logError(error);
      }).finally(function () {
        _this.warrantyLoading = false;

        _this.getWarranty();
      });
    },
    voidWarranty: function voidWarranty() {
      var _this = this;

      _this.warrantyLoading = true;

      _this.$store.dispatch(DELETE, {
        url: "warranty/" + _this.warrantyDetail.id
      }).then(function () {
        _this.voidWarrantyDialog = false;
        _this.detailDialog = false;
        _this.warrantyDetail = new Object();
        _this.productType = null;
        _this.productBarcode = null;
        _this.productName = null;
      }).catch(function (error) {
        _this.logError(error);
      }).finally(function () {
        _this.warrantyLoading = false;

        _this.getWarranty();
      });
    },
    detailDialogConfirm: function detailDialogConfirm(row) {
      this.warrantyDetail = row;
      this.productType = row.product_type == "equipment" ? "Equipment" : "Product";
      this.productBarcode = row.product_barcode;
      this.productName = row.product_name;
      this.detailDialog = true;
    },
    getJobs: function getJobs() {
      var _this = this;

      _this.$store.dispatch(GET, {
        url: "job/list-all"
      }).then(function (_ref2) {
        var data = _ref2.data;
        _this.jobList = data;
      }).catch(function (error) {
        _this.logError(error);
      });
    },
    getWarrantyProducts: function getWarrantyProducts() {
      var _this = this;

      _this.$store.dispatch(GET, {
        url: "product/warranty"
      }).then(function (_ref3) {
        var data = _ref3.data;
        _this.productList = data;
      }).catch(function (error) {
        _this.logError(error);
      });
    },
    getWarranty: function getWarranty() {
      var _this = this;

      var filter = {
        search: _this.filter.search || undefined,
        customer: _this.lodash.toSafeInteger(_this.filter.customer) || undefined,
        product: _this.lodash.toSafeInteger(_this.filter.product) || undefined,
        job: _this.lodash.toSafeInteger(_this.filter.job) || undefined,
        daterange: _this.dates || undefined
      };

      if (_this.lodash.isEmpty(_this.dates) === false) {
        if (moment(_this.dates[0]).isAfter(_this.dates[1])) {
          ErrorEventBus.$emit("update:error", InitializeError("End Date must be after Start Date."));
          return false;
        }
      } else {
        filter.daterange = undefined;
      }

      var per_page = _this.paginationPageSize;
      var current_page = _this.currentPage;
      var status = _this.status;
      clearTimeout(_this.timeout);
      _this.warranty = new Array();
      _this.dataLoading = true;
      _this.timeout = setTimeout(function () {
        _this.$store.dispatch(QUERY, {
          url: "warranty",
          data: {
            filter: filter,
            per_page: per_page,
            current_page: current_page,
            status: status
          }
        }).then(function (_ref4) {
          var data = _ref4.data;
          _this.totalPages = Math.ceil(data.total_rows / data.per_page);
          _this.currentPage = data.current_page;
          _this.warranty = data.rows;
          _this.totalRows = data.total_rows;
          _this.rowsOffset = data.offset;
          _this.statusList = data.status_list;
        }).catch(function (error) {
          _this.logError(error);
        }).finally(function () {
          _this.datePicker = false;
          _this.warrantyLoading = false;
          _this.dataLoading = false;
          _this.detailDialog = false;
          _this.productType = null;
          _this.productBarcode = null;
          _this.productName = null;
          _this.updateWarrantyDialog = false;
          _this.addWarrantyDialog = false;
          _this.voidWarrantyDialog = false;
        });
      }, _this.timeoutLimit);
    },
    getAllCustomers: function getAllCustomers() {
      var _this = this;

      _this.$store.dispatch(GET, {
        url: "customer-list"
      }).then(function (_ref5) {
        var data = _ref5.data;
        _this.allCustomerList = data;
      }).catch(function (error) {
        _this.logError(error);
      });
    },
    getAllProducts: function getAllProducts() {
      var _this = this;

      _this.$store.dispatch(GET, {
        url: "product/get-line-product-service"
      }).then(function (_ref6) {
        var data = _ref6.data;
        _this.allProductList = data;
      }).catch(function (error) {
        _this.logError(error);
      });
    },
    pushQueryParams: function pushQueryParams() {
      var _this = this;

      if (!_this.lodash.isEqual(_this.$route.query, _this.lodash.omitBy(_this.queryParams, _this.lodash.isNil))) {
        _this.$router.replace(_this.getDefaultRoute("warranty", {
          query: _this.queryParams
        })).then(function () {
          _this.getWarranty();
        }).catch(function (error) {
          _this.logError(error);
        });
      } else {
        _this.getWarranty();
      }
    }
  },
  watch: {
    $route: function $route() {
      this.status = this.$route.query.status;
      this.getWarranty();
    },
    searchProduct: function searchProduct(new_param, old_param) {
      if (new_param != old_param) {
        this.updateSearch(new_param);
      }
    },
    status: function status(params) {
      this.currentPage = 1;

      if (params) {
        this.lodash.assign(this.queryParams, {
          status: params
        });
        this.pushQueryParams();
      }
    }
  },
  mounted: function mounted() {
    var _this = this;

    _this.getWarranty(); // _this.getJobs();


    _this.getAllCustomers();

    _this.getAllProducts();

    _this.getWarrantyProducts();

    _this.$store.dispatch(SET_BREADCRUMB, [{
      title: "Warranty"
    }]);

    _this.status = _this.$route.query.status || _this.status;
    PaginationEventBus.$on("update:pagination", function (param) {
      _this.currentPage = param;

      _this.getWarranty();
    });
  },
  computed: {
    filteredProductList: function filteredProductList() {
      return this.filteredProducts.length > 0 ? this.filteredProducts : this.searchProduct ? [] : this.allProductList;
    },
    showingFrom: function showingFrom() {
      return this.lodash.isEmpty(this.warranty) === false ? this.rowsOffset + 1 : 0;
    },
    showingTo: function showingTo() {
      return this.rowsOffset + (this.warranty ? this.warranty.length : 0);
    },
    dialogWidth: function dialogWidth() {
      return this.lodash.toSafeInteger(document.body.clientWidth / 100 * 50);
    }
  }
};